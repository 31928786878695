import React from 'react';
import './Footer.css';
import scLogo from './assets/images/security-logo.png';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
      <footer className="footer">
        <p>
          <a href="https://beian.miit.gov.cn/" 
            target="_blank" 
            rel="noreferrer"
            margin-right="10px">
              粤ICP备2024317790号-1
          </a>
          <img src={scLogo} width="20"/> 
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=44030002004915" 
            rel="noreferrer" 
            target="_blank">粤公网安备44030002004915
          </a>
        </p>
        <p>@2024-{currentYear} zed168.com All rights reserved.</p>
      </footer>
    );
  };

export default Footer;